@keyframes menuSlide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes changeZIndex {
  0% {
    z-index: -1;
  }

  100% {
    z-index: 1;
  }
}

.menu {
  position: absolute;
  z-index: 1099;
  opacity: 0;
  height: 0;
  // overflow-x: hidden;

  @include desktopMenu {
    opacity: 1;
    height: initial;
    // overflow-x: unset;
  }

  a.register {
    @extend .btn;
    @extend .btn-primary;
    width: 100%;
    margin-top: 8px;
    font-size: 100% !important;
    color: #fff !important;

    &:after {
      display: none !important;
    }

    @include desktopMenu {
      margin-top: 11px;
      height: 42px !important;
      line-height: 40px !important;
    }
  }

  .image-logo {
    width: 42px;
    height: 42px;
    flex-grow: 2;
    border-radius: 42px;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    position: relative;

    .warning {
      display: block;
      height: 20px;
      width: 20px;
      background-image: url(/svg/ic-warning.svg);
      background-size: 20px 20px;
      position: absolute;
      top: -5px;
      right: -6px;
    }

    strong {
      position: absolute;
      left: 58px;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      text-transform: initial;

      @include desktopMenu {
        display: none;
      }
    }
  }

  .search-container {
    position: absolute;
    z-index: 1;
    bottom: -60px;
    right: 0;
    left: auto;
    height: 56px;
    width: 0;

    &:hover {
      .ico {
        opacity: 0.7;
      }
    }

    .ico {
      opacity: 1;
      position: absolute;
      right: 54px;
      top: 14px;
      content: '';
      width: 24px;
      height: 24px;
      background-image: url(/svg/ic-search.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 24px;
      z-index: 9999;
      pointer-events: none;
      animation: changeZIndex 0.01s forwards;
      animation-delay: 0.25s;
      transition: opacity 0.2s ease;
      display: none;

      @include desktopMenu {
        right: 30px;
        display: block;
      }
    }

    @include desktopMenu {
      position: relative;
      bottom: initial;
      top: 6px;
      width: auto;
      padding-right: 30px;
    }
  }

  label {
    background: none;
    display: inline;
    width: 80px;
    position: absolute;
    right: -30px;
    top: -2px;
    bottom: 0;
    cursor: pointer;
    left: initial;
    font-size: 0;
    opacity: 1;

    @include desktopMenu {
      cursor: pointer;
      display: flex;
      justify-content: center;
      right: 0;
      height: 100%;
      width: 80px;
      margin-left: -30px;
      margin-right: -30px;
      position: relative;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    width: 300vw;
    height: 1000vh;
    top: -100vh;
    left: -100vw;
    z-index: 1;
    content: '';
    background: rgba($color-body-bg, 0.7);
  }

  input[type='text'] {
    transform: translateY(-100%);
    width: calc(100vw - 30px);
    height: 44px;
    background-color: $color-input-search-bg;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 0;
    position: absolute;
    right: 5px;
    top: 4px;
    z-index: 1;
    padding: 0;
    opacity: 0;
    transition: 0.25s ease-in-out;
    transition-property: transform, opacity;
    padding-left: 44px;
    padding-right: 15px;
    z-index: 2;
    background-image: url(/svg/ic-search.svg);
    background-position: 10px 50%;
    background-repeat: no-repeat;

    @include desktopMenu {
      width: 420px;
      right: 16px;
      transform: translateY(-100%);
    }
  }

  .cancel {
    width: 44px;
    height: 44px;
    display: block;
    background-image: url(/svg/ic-cancel.svg);
    background-position: 50% 50%;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    position: absolute;
    text-indent: -9999px;
    opacity: 0;
    transform: translateY(-100%);
    cursor: pointer;
    z-index: 2;
    transition: 0.25s ease-in-out;
    right: 15px;
    top: 4px;
    transition-property: opacity, transform;

    @include desktopMenu {
      right: 16px;
    }
  }

  &:before {
    opacity: 0;
    display: block;
    content: '';
  }

  &.logged-in {
    & > ul {
      & > li {
        &.gift-vouchers {
          margin-right: 29px;
        }

        &.set-language {
          margin-right: 135px !important;
        }
      }
    }
  }

  & > ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding: 0 20px 20px 20px;
    font-weight: bold;
    height: 0;
    overflow: hidden;

    & > li {
      width: 100%;
      display: block;
      margin: 0;
      border-top: 1px solid $color-border-inverted;

      &.gift-vouchers {
        background-image: url(/png/icGift.png);

        @include desktopMenu {
          position: relative;
          order: 1;
          margin-left: auto;
          padding-left: 28px;
          background-size: 16px 16px;
          background-repeat: no-repeat;
          background-position: 0 50%;

          &:after {
            content: '';
            display: block;
            width: 1px;
            background-color: $color-input-focus-border;
            height: 24px;
            position: absolute;
            right: -31px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        @include desktopMenuIconsOnly {
          font-size: 0;
          text-indent: -9999px;
          width: 16px;
          padding-left: 0;
        }
      }

      &.set-language {
        display: none;

        margin-right: 0;

        @include desktopMenu {
          order: 1;
          position: relative;
          display: flex;
          align-items: center;
          margin-right: auto;

          &:hover {
            .language-list {
              height: auto;
              opacity: 1;
              transition: height, opacity 0.2s ease;

              a {
                visibility: visible;
              }
            }
          }

          &:after {
            content: '';
            display: block;
            width: 1px;
            background-color: $color-input-focus-border;
            height: 24px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          .lang-flag {
            cursor: pointer;
            padding-left: 12px;
            padding-right: 12px;
          }
        }

        .language-list {
          display: flex;
          position: absolute;
          flex-direction: column;
          top: 56px;
          right: -25px;
          background-color: #fff;
          border-radius: 4px;
          width: 220px;
          height: 0;
          opacity: 0;
          z-index: -2;

          a {
            visibility: hidden;
            padding: 14px 16px;
            color: #2f3542;
            cursor: pointer;
            transition: background-color 0.2s ease;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;

            &:hover {
              background: rgba(47, 53, 66, 0.08);
            }
          }
        }
      }

      & > a {
        display: block;
        height: 64px;
        line-height: 64px;

        &:hover {
          color: $color-primary;
        }
      }
    }

    .voucher_menu {
      margin-right: 20px !important;
      a {
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .personal {
      background-image: initial;
    }

    .collapse-menu {
      display: flex;
      align-items: center;
      position: relative;

      & > a {
        color: #fff;
        transition: opacity 0.25s;
        display: flex;
        align-items: center;

        &:after {
          content: '';
          display: inline-block;
          // margin-bottom: -6px;
          margin-left: 4px;
          width: 24px;
          height: 24px;
          background-image: url(/svg/ic-chevron-menu.svg);
          transform: rotate(0deg);
          transition: transform 0.25s;
        }
      }

      &-opened {
        & > a {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &:hover {
        & > a {
          @include desktopMenu {
            &:after {
              opacity: 0.7;
              transform: rotate(180deg);
            }
          }
        }
        @include desktopMenu {
          &::before {
            content: '';
          }
          .primary-list {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);

            li {
              background: white;

              &:first-child {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
              }

              &:last-child {
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
              }

              &:hover {
                background: #e0e0e3;
              }
            }
          }
        }
      }

      &:hover {
        & > a {
          @include desktopMenu {
            color: #fff;
            // opacity: 0.7;
          }
        }
      }

      .primary-list {
        & > li {
          position: relative;

          &:after {
            content: '';
            display: block;
            top: 0;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            position: absolute;
          }
        }
      }

      .primary-list,
      .sub-menu {
        overflow-y: visible;
        list-style: none;
        visibility: hidden;
        // height: auto;
        position: absolute;
        display: none;
        background-color: #fff;
        top: 62px;
        left: -16px;
        border-radius: $border-radius-secondary;
        padding: 0;
        height: 0;
        opacity: 0;
        display: block;
        // transform: translateY(10px);
        transition: height, opacity 0.25s, transform 0.2s;

        .sub-menu {
          overflow-y: scroll;
        }

        li {
          width: 220px;
          height: 48px;

          @include desktopMenu {
            &:hover {
              background: rgba($color-gradient-base, 0.12);
            }
          }

          a {
            display: inline-block;
            font-size: 16px;
            color: #2f3542;
            font-weight: normal;
            height: 100%;
            padding: 14px 0 15px 20px;

            @include desktopMenu {
              padding-left: 14px;
            }

            width: 100%;
          }
        }

        .with-icon {
          a {
            display: flex;
            align-items: center;
            padding: 14px 20px;
            justify-content: space-between;

            @include desktopMenu {
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          &:after {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .support-theater:after {
          background-image: url(/svg/ic-heart.svg);
        }

        .gift-vouchers:after {
          background-image: url(/png/icGift.png);
        }

        .for-schools:after {
          background-image: url(/svg/ic-books.svg);
        }

        .christmas-contest::after {
          background-image: url(/png/christmas-tree.png);
        }

        .border-bottom-1px {
          border-bottom: 1px solid rgba($color-gradient-base, 0.12);
        }
      }

      .all-genres {
        @include desktopMenu {
          &:hover {
            .sub-menu {
              opacity: 1;
              visibility: visible;
              height: auto;
              transform: translateY(0);
            }
          }
        }

        &::after {
          background-image: url(/svg/ic-chevron-small.svg) !important;
        }
      }

      .sub-menu {
        left: 222px;
        top: -144px;
        max-height: 400px;

        &.shifted {
          top: 0;
          overflow-y: hidden;
        }
      }
    }
  }

  @include desktopMenu {
    background: transparent;
    position: initial;
    display: block;
    transform: initial;
    opacity: initial;
    transition: initial;
    width: initial;
    height: initial;
    top: initial;
    left: initial;
    transition-property: initial;

    // &.logged-in {
    //   & > ul {
    //     .user-
    //   }
    // }

    & > ul {
      flex-direction: row;
      align-items: initial;
      margin: initial;
      margin-top: 0;
      margin-right: -30px;
      height: 64px;
      justify-content: flex-start;
      padding-left: 194px;
      overflow: initial;

      .try-free {
        margin: 0;
        margin-right: 30px;
        order: 3;
      }

      .user-menu {
        position: absolute;
        right: 0;
        height: 64px;
      }

      .login {
        order: 2;
        margin: 0 10px;
      }

      .menu-item {
        z-index: 3;
        margin-right: 30px;
        height: 64px;
      }

      .personal {
        text-indent: 28px !important;
        background-image: url(/svg/ic-avatar.svg);
      }

      & > li {
        position: relative;
        width: initial;
        border: initial;

        a {
          white-space: pre;
        }

        &:first-child {
          margin-right: 30px;
        }
      }

      a {
        color: $color-body-color;

        &:not([class]) {
          &:hover {
            color: rgba($color-body-color, 0.7);
          }
        }

        &[class] {
          padding: 0 20px;
          position: relative;

          &:hover {
            color: inherit;

            &:after {
              border-color: $color-body-color;
            }
          }

          &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            bottom: 10px;
            content: '';
            display: block;
            border-radius: $border-radius-global;
            transition: 0.5s ease-in-out;
            transition-property: border-color;
            border: 1px solid transparent;
          }
        }
      }
    }

    .user-menu {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 30px !important;
      cursor: pointer;

      .logo-arrow {
        background-image: url(/svg/ic-chevron-white.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .content {
        display: flex;
        position: absolute;
        opacity: 0;
        height: 0;
        flex-direction: column;
        justify-content: left;
        top: 62px;
        right: 15px;
        border-radius: $border-radius-secondary;
        overflow: hidden;

        transition: height, opacity 0.25s, transform 0.2s;

        a {
          color: $color-dark;
          background-color: #fff;
          padding: 14px 15px;
          width: 190px;
          font-weight: normal;
          border-color: inherit !important;
          transition: 0.1s ease-in-out;
          transition-property: background-color;

          &:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }

          &:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
          }

          &:hover {
            color: $color-dark;
            background-color: #eeeff0;
          }
        }

        .logout {
          color: $color-primary !important;
          font-weight: bold;

          &::after {
            border-color: transparent;
          }
        }
      }

      &:hover .content {
        height: auto;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

// search

#result-list {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.25s ease-in-out;
  transition-property: width, height, opacity;
}

.searching {
  .nav-toggle {
    z-index: 3;
  }

  .overlay {
    display: block;
  }

  .search {
    opacity: 0;
  }

  .menu {
    li:not(.search-container, .login, .try-free, .user-menu, .result-item, .gift-vouchers, .set-language) {
      opacity: 0;
      z-index: -1;
    }

    input[type='text'] {
      background-color: $color-input-search-bg;
      transform: translateY(0);
      opacity: 1;

      @include desktopMenu {
        transform: translateY(0);
      }

      & ~ .cancel {
        transform: translateY(0);
        opacity: 1;
        z-index: 9999;
      }

      & ~ .ico {
        opacity: 0;
        z-index: -1;
      }

      & ~ label {
        opacity: 0;
        z-index: 0;
      }

      & ~ .overlay {
        display: block;

        @include desktopMenu {
          display: none;
        }
      }

      &:not(:placeholder-shown) {
        & ~ #result-list {
          display: block;
          width: auto;
          opacity: 1;
        }
      }
    }
  }
}

.result-list {
  background-color: $color-body-color;
  border-radius: $border-radius-big;
  width: calc(100vw - 30px);
  height: auto;
  position: absolute;
  top: 60px;
  right: 0;
  max-height: calc(100vh - 75px);
  overflow: auto;
  z-index: 5;
  color: $color-dark;
  padding: 10px;

  @include desktopMenu {
    width: 420px;
    max-height: 50vh;
    left: -386px;
  }

  h3 {
    background-color: rgba($color-dark, 0.08);
    border-radius: $border-radius-secondary;
    font-size: 88.88888889%;
    height: 32px;
    line-height: 32px;
    text-indent: 10px;
    margin-bottom: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      opacity: 1 !important;

      &:last-child {
        border: 0;
      }

      em {
        font-style: normal;
        font-weight: normal;
      }

      a {
        padding: 15px 10px !important;
        display: flex;
        flex-direction: column;
        color: $color-dark;

        &:hover,
        &.active {
          color: $color-primary !important;
        }

        strong {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  a {
    color: inherit;
  }
}

.empty {
  padding: 0;
  ul li {
    padding: 25px 20px;
  }
}

.hide {
  display: none !important;
}

.mobile-menu-open {
  z-index: 1099;

  body {
    position: absolute;
    height: 100vh;
    overflow: hidden;
  }

  header {
    background-color: $color-body-bg;
  }

  .menu-search {
    display: none;
  }

  .nav-toggle {
    background-size: 24px 24px;
    background-image: url(/svg/ic-menu-close.svg);
  }

  .menu {
    user-select: none;
    opacity: 1;
    background-color: $color-body-bg;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 140px;
    position: absolute;
    margin-top: 64px;
    top: 0;

    .collapse-menu {
      .sub-menu {
        max-height: initial;
      }

      &-opened {
        display: flex;
        flex-direction: column;
        align-items: initial;

        &.all-genres {
          height: auto;

          & > ul {
            padding: 0;
          }

          & > a {
            display: none !important;
          }
        }

        & > ul {
          padding: 10px 0;
          opacity: 1;
          visibility: visible;
          position: initial;
          height: initial;
          overflow: initial;
          background-color: #121222;

          li {
            width: auto;

            &.gift-vouchers {
              display: none;
            }

            a,
            a:visited {
              font-weight: bold;
              color: white;
            }

            &.border-bottom-1px {
              padding-bottom: 64px;
              margin-bottom: 10px;
              position: relative;
              border: 0;

              &:before {
                content: '';
                display: block;
                left: 20px;
                right: 20px;
                bottom: 0;
                top: initial !important;
                height: 1px;
                position: absolute;
                background-color: $color-border;
              }

              @include desktopMenu {
                border-bottom: 1px solid $color-border !important;

                &:before {
                  display: none;
                }
              }

              a {
                height: 48px;
              }
            }
          }
        }
      }
    }

    .unlogged {
      &.login {
        display: none;
      }

      &.try-free {
        position: relative;
        margin-top: 40px;

        &:before {
          display: block;
          content: '';
          height: 1px;
          background-color: $color-border;
          left: 0;
          right: 0;
          top: -25px;
          position: absolute;
        }
      }
    }

    .login-prompt {
      display: block;
      text-align: center;
      margin: 40px 0 0 0;
      font-weight: normal;

      a {
        color: #d73231;
        font-weight: bold;
      }
    }

    .user-menu {
      margin-top: 24px;
      padding-top: 32px;
      position: relative;

      &:before {
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: $color-border;
      }

      .image-logo {
        margin-bottom: 23px;
      }

      .content {
        & > a {
          display: block;
          height: 56px;
          line-height: 56px;
          font-weight: normal;
        }
      }
    }

    & > ul {
      font-size: 111.11111111%;
      width: 100%;
      padding: 17px 20px 0 20px;
      padding-bottom: 60px;
      display: block;
      height: initial;

      &:before {
        content: '';
        display: block;
        height: 1px;
        position: fixed;
        left: 0;
        right: 0;
        top: 64px;
        background-color: $color-border;
        z-index: 1501;
      }

      & > li {
        &.menu-item {
          &.with-icon {
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: calc(100% - 4px) 50%;
          }

          &.gift-vouchers {
            display: block;
          }
        }

        &.menu-home {
          display: none;
        }

        &.search-container {
          bottom: initial;
          top: -10px;

          .ico,
          label {
            display: none;
          }
        }

        & > a {
          height: 56px;
          line-height: 56px;
        }
      }

      .collapse-menu {
        & > a {
          width: 100%;

          &:hover {
            color: white;
          }

          &:after {
            position: absolute;
            right: 0;
            margin-bottom: 0;
            top: 14px;
          }
        }
      }

      .primary-list {
        margin-bottom: 20px;
      }
    }
  }
}

.btn-register-header {
  @extend .btn;

  position: absolute;
  z-index: 1500;
  height: 35px;
  line-height: 35px;
  right: 64px;
  top: 14px;
  display: none;
}

.menu {
  & > ul {
    & > li {
      &.menu-search,
      .login-prompt {
        display: none;
      }
    }
  }
}

@include mobileMenu {
  .searching {
    body {
      overflow: hidden;
    }

    .menu {
      opacity: 1;
      width: 100vw;
      top: 5px;
      z-index: 1500;

      & > ul {
        top: 10px;

        &:before {
          display: none;
        }

        & > li {
          &.unlogged {
            opacity: 0;
          }

          &.search-container {
            top: 0;

            .result-list {
              right: 13px;
            }

            input {
              right: 14px;
            }
          }
        }
      }
    }
  }
}

.home {
  @include mobileMenu {
    .header-content {
      div {
        .search {
          display: none;
        }
      }

      .btn-register-header {
        display: block;
      }
    }
  }

  header {
    .logo {
      transition-property: transform, opacity;

      &:before {
        transform: translateY(0);
        transition: 0.25s ease-in-out;
        transition-property: inherit;
      }

      &:after {
        background-image: url(/svg/ic-logo-circle.svg);
        background-repeat: no-repeat;
        background-position: 0 50%;
        padding-left: 34px;
        position: absolute;
        top: 0;
        left: 0;
        content: attr(mobile-text);
        display: flex;
        align-items: center;
        width: 180px;
        height: 100%;
        text-indent: initial;
        line-height: 19px;
        font-size: 88.88888889%;
        font-weight: bold;
        opacity: 0;
        transform: translateY(-8px);
        transition: 0.25s ease-in-out;
        transition-property: inherit;
      }
    }
  }

  &:not(.home-content) {
    .btn-register-header {
      @extend .btn-outline-info;
    }
  }

  &-content {
    .btn-register-header {
      @extend .btn-primary;
    }

    header.blendLogo {
      @include mobileMenu {
        .logo {
          &:before {
            opacity: 0;
            transform: translateY(-8px);
          }

          &:after {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }

  &.mobile-menu-open {
    .menu-search {
      display: block;

      label {
        font-size: inherit;
        position: initial;
        color: white;
        display: block;
        width: 100%;
        right: initial;
        left: initial;
        top: initial;
        background-image: url(/svg/ic-search.svg);
        background-size: 24px 24px;
        background-position: 100% 10px;
        background-repeat: no-repeat;
        border-bottom: 1px solid $color-border;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
