// bootstrap override + custom vars
$font-family-sans-serif: 'proxima-nova', Arial, sans-serif !default;

@import 'colors';
@import 'vars';
@import 'animations';
@import 'mixins';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/swiper/css/swiper.min';
@import 'node_modules/shaka-player/dist/controls.css';
@import 'form';
@import 'overrides';
@import 'details';
@import 'components/all';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-size: 1.125em;
  background: $color-body-bg;
  color: $color-body-color;
  overflow-x: hidden;
  user-select: none;
}

a {
  color: $color-body-color;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  transition-property: color, border-color;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

h1 {
  font-weight: bold;
  font-size: 1.5555555556em;
}

h2 {
  font-weight: bold;
}

.hidden {
  display: none !important;
}

// cache purposes, so the assets get grabbed by parcel
wbr {
  display: none;
  background-image: url(/svg/img-play-placeholder.svg);
}

.dialog-open {
  .overlay {
    opacity: 1;
    z-index: 5;
  }
}

.dialog-open:not(.allow-scrolling) .fixed-top {
  z-index: 0;
}

.overlay {
  transition: opacity 0.25s ease-out;
  position: fixed;
  width: 300vw;
  height: 300vh;
  left: -150vw;
  top: -150vh;
  background: rgba(1, 1, 24, 0.7);
  z-index: -2;
  opacity: 0;
  display: block;
  content: '';
}
