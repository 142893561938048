@import 'styles/colors';

body {
  [data-tip] {
    cursor: help;
  }

  .dramox-tooltip {
    background: $color-body-color;
    color: $color-dark;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: initial;
    text-align: center;

    &.show {
      opacity: 1;
    }

    &.place-top {
      &:after {
        border-top-color: $color-body-color !important;
      }
    }

    &.place-left {
      &:after {
        border-left-color: $color-body-color !important;
      }
    }
  }
}
