.theatre-wrapper {
  width: 100%;
  max-width: 770px;
  margin-top: 16px;

  .category-section {
    .play-list {
      li:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .theatre-section {
    min-height: 201px;

    .play-list {
      height: auto;
      li {
        width: 224px;
        height: 160px;

        img {
          margin-bottom: 10px;
        }

        a {
          width: 100%;
        }

        a > div {
          // display: none;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          line-height: 29px;
          border-radius: 6px;
          background: rgba(72, 72, 72, 0.48);
          width: 100%;
          height: 126px;
        }

        &:after {
          display: none;
        }

        @include desktop {
          width: 170px;
          height: 127px;

          a > div {
            height: 96px;
          }
        }

        &:hover {
          opacity: 0.72;
        }
      }

      .with-number {
        h3 {
          display: none;
        }

        a > div {
          display: flex;
        }

        img {
          display: none;
        }
      }
    }
  }
}

.scroll-wrapper-theatre {
  overflow: hidden;
  padding: 0 15px;
  margin: 0 -15px;

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-wrapper {
    width: auto;
  }
}

@include desktop {
  .theatre-wrapper {
    margin-top: 24px;

    .category-section {
      .play-list {
        li:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
}
