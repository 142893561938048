.video-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 100%;
    height: 100%;
  }

  @media (orientation: portrait) {
    video {
      height: initial;
    }
  }
}

.shaka-video-container * {
  font-family: inherit !important;
}

// SHAKA styles

// global panel

.shaka-bottom-controls {
  position: fixed;
  left: 0;
  top: initial;
  bottom: 0;
  width: 100vw;
  height: 110px;
  z-index: 3;
}

.shaka-controls-button-panel {
  height: 100%;
  width: 100%;
  position: absolute;
}

// time

.shaka-current-time {
  position: absolute;
  bottom: 27px;
  width: 135px;
  text-align: right;
  z-index: 10000;
  white-space: nowrap;
  margin: 0;
  font-size: 16px;
  color: $color-body-color;
  left: 10px;

  @include desktop {
    left: 25px;
  }
}

// common range

.shaka-range-container {
  position: fixed;
  background: $color-input-focus-border;

  input {
    height: 100%;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: -4px;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: -4px;
  }

  input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: -4px;
  }

  .shaka-range-element {
    top: 0;
  }
}

// seekbar

.shaka-seek-bar-container {
  left: 20px;
  top: initial;
  width: calc(100vw - 40px);
  bottom: 75px;
  margin: 0;
  height: 5px;
  border-radius: 5px;

  &:hover {
    height: 10px;
    bottom: 72px;
    border-radius: 10px;
  }

  @include desktop {
    left: 40px;
    width: calc(100vw - 80px);
  }
}

.shaka-controls-container[shown='true'] {
  .shaka-seek-bar-container {
    transition: 0.1s linear;
  }
}

// volume bar

.shaka-volume-bar-container {
  width: 100px;
  left: 229px;
  bottom: 32px;
  top: initial;

  input[type='range']::-webkit-slider-thumb {
    height: 13px;
    width: 13px;
    margin-top: -1px;
  }

  input[type='range']::-moz-range-thumb {
    height: 13px;
    width: 13px;
    margin-top: 3px;
  }

  input[type='range']::-ms-thumb {
    height: 13px;
    width: 13px;
    margin-top: 3px;
  }
}

// play_pause big

$radius: 65px;
.shaka-play-pause-custom {
  width: $radius * 2;
  height: $radius * 2;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -$radius;
  margin-top: -$radius;
  background-image: url(/svg/player/ic-pause.svg);
  background-size: $radius * 2 $radius * 2;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  z-index: 3;
  text-indent: -9999px;
  font-size: 0;

  &:before {
    background-image: url(/svg/player/ic-play-hover.svg);
    content: '';
    display: block;
    visibility: hidden;
  }

  &:after {
    background-image: url(/svg/player/ic-pause.svg);
    content: '';
    display: block;
    visibility: hidden;
  }

  @media (hover: hover) {
    &:hover {
      background-image: url(/svg/player/ic-pause-hover.svg);
    }
  }

  &.paused {
    background-image: url(/svg/player/ic-play.svg);

    &:before {
      background-image: url(/svg/player/ic-pause-hover.svg);
      content: '';
      display: block;
      visibility: hidden;
    }

    @media (hover: hover) {
      &:hover {
        background-image: url(/svg/player/ic-play-hover.svg);
      }
    }
  }
}

// all bottom buttons

.shaka-skip-forward,
.shaka-skip-backward,
.shaka-small-play-button,
.shaka-airplay-button,
.shaka-fullscreen-button,
.shaka-overflow-menu-button,
.shaka-mute-button {
  margin: 0;
  width: 36px;
  height: 36px;
  background-size: 28px 28px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  text-indent: -9999px;
  transition: 0.15s linear;

  @media (hover: hover) {
    &:hover {
      background-color: $color-input-focus-border;
    }
  }
}

// mute

.shaka-mute-button {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.57143 10.8571L12.4286 4V24.5714L5.57143 17.7143H3.28571C2.02286 17.7143 1 16.6914 1 15.4286V13.1429C1 11.88 2.02286 10.8571 3.28571 10.8571H5.57143ZM10.1429 19.0526V9.51771L7.18743 12.4731L6.51771 13.1429H5.57143H3.28571V15.4286H5.57143H6.51771L7.18743 16.0971L10.1429 19.0526Z' fill='white'/%3E%3Cpath d='M17.3786 9.33559C20.1127 12.0697 20.1125 16.5016 17.3787 19.2344L18.7927 20.6489C22.308 17.1348 22.3078 11.4364 18.7928 7.92138L17.3786 9.33559ZM14.1454 12.5687C15.0943 13.5176 15.0943 15.0548 14.1454 16.0037L15.5596 17.4179C17.2896 15.6879 17.2896 12.8845 15.5596 11.1545L14.1454 12.5687ZM20.6106 6.10359C25.1298 10.6228 25.1297 17.9496 20.6107 22.4676L22.0247 23.882C27.3251 18.5828 27.325 9.98957 22.0248 4.68938L20.6106 6.10359Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: 28px 28px;
  left: 171px;

  @include desktop {
    left: 181px;
  }

  &[aria-label='Unmute'],
  &[aria-label='Zapnout zvuk'] {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.57143 10.8571L12.4286 4V24.5714L5.57143 17.7143H3.28571C2.02286 17.7143 1 16.6914 1 15.4286V13.1429C1 11.88 2.02286 10.8571 3.28571 10.8571H5.57143ZM10.1429 19.0526V9.51771L7.18743 12.4731L6.51771 13.1429H5.57143H3.28571V15.4286H5.57143H6.51771L7.18743 16.0971L10.1429 19.0526Z' fill='white'/%3E%3Cpath d='M15 19L25 9' stroke='white' stroke-width='2'/%3E%3Cpath d='M25 19L15 9' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
}

// ff + rewind

.shaka-skip-backward {
  background-image: url(/svg/player/ic-backward.svg);
  left: calc(50% - 18px - 32px - 36px);
}

.shaka-skip-forward {
  background-image: url(/svg/player/ic-forward.svg);
  left: calc(50% - 18px + 32px + 36px);
}

// play_pause small

.shaka-small-play-button {
  left: calc(50% - 18px);
  background-image: url(/svg/player/ic-pause-small.svg);

  &[aria-label='Spustit'],
  &[aria-label='Play'] {
    background-image: url(/svg/player/ic-play-small.svg);
  }
}

// airplay

.shaka-airplay-button {
  background-image: url(/svg/player/ic-airplay.svg);
  right: 120px;

  @include desktop {
    right: 140px;
  }
}

// fullscreen

.shaka-fullscreen-button {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5V4H4V5H5ZM5 23H4V24H5V23ZM23 23V24H24V23H23ZM23 5H24V4H23V5ZM6 10.625V5H4V10.625H6ZM5 6H10.625V4H5V6ZM10.625 22H5V24H10.625V22ZM6 23V17.375H4V23H6ZM22 17.375V23H24V17.375H22ZM23 22H17.375V24H23V22ZM17.375 6H23V4H17.375V6ZM22 5V10.625H24V5H22Z' fill='white'/%3E%3C/svg%3E%0A");
  right: 20px;

  @include desktop {
    right: 40px;
  }
}

// overflow menu

.shaka-overflow-menu-button {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.54907 14C8.54907 16.7586 10.7905 19 13.5491 19C16.3076 19 18.5491 16.7586 18.5491 14C18.5491 11.2414 16.3076 9 13.5491 9C10.7905 9 8.54907 11.2414 8.54907 14ZM13.5491 17C11.8951 17 10.5491 15.654 10.5491 14C10.5491 12.346 11.8951 11 13.5491 11C15.2031 11 16.5491 12.346 16.5491 14C16.5491 15.654 15.2031 17 13.5491 17Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3915 3L9.84587 5.80508C9.2988 6.05062 8.78056 6.34752 8.2979 6.69346L5.60265 5.76526L2.44336 11.2344L4.59913 13.1088C4.56641 13.4106 4.54895 13.7064 4.54895 14C4.54895 14.2942 4.56649 14.5903 4.59894 14.892L2.4436 16.767L5.60265 22.2357L8.2979 21.3075C8.78059 21.6535 9.29886 21.9504 9.84597 22.196L10.3916 25H16.7071L17.2516 22.1947C17.7984 21.9492 18.3165 21.6524 18.799 21.3065L21.4943 22.2347L24.6533 16.766L22.4987 14.8916C22.5315 14.5897 22.549 14.2937 22.549 14C22.549 13.7063 22.5315 13.4102 22.4987 13.1083L24.6543 11.233L21.4953 5.76426L18.8 6.69246C18.3173 6.3465 17.799 6.04959 17.2519 5.80404L16.7063 3H10.3915ZM15.498 7.261L15.058 5H12.04L11.6 7.262L11.079 7.459C10.352 7.733 9.69095 8.113 9.11395 8.587L8.68195 8.941L6.50995 8.193L4.99995 10.807L6.73895 12.319L6.64995 12.868C6.58195 13.285 6.54895 13.655 6.54895 14C6.54895 14.345 6.58195 14.715 6.64895 15.133L6.73795 15.682L4.99995 17.194L6.50995 19.808L8.68195 19.06L9.11395 19.414C9.69095 19.888 10.352 20.268 11.079 20.542L11.6 20.739L12.04 23H15.058L15.497 20.738L16.018 20.541C16.745 20.267 17.406 19.887 17.983 19.413L18.415 19.059L20.587 19.807L22.097 17.193L20.359 15.681L20.448 15.132C20.516 14.715 20.549 14.345 20.549 14C20.549 13.655 20.516 13.285 20.448 12.868L20.36 12.318L22.098 10.806L20.588 8.192L18.416 8.94L17.984 8.586C17.407 8.112 16.746 7.732 16.019 7.458L15.498 7.261Z' fill='white'/%3E%3C/svg%3E%0A");
  right: 70px;

  @include desktop {
    right: 90px;
  }
}

[casting='true'] {
  .shaka-overflow-menu-button,
  .shaka-overflow-menu {
    right: 20px;

    @include desktop {
      right: 40px;
    }
  }
}

.shaka-overflow-menu,
.shaka-settings-menu {
  background: $color-player-menu-bg;
  backdrop-filter: blur(32px);
  border-radius: 4px;
  bottom: 100px;
  max-height: 300px;
  z-index: 4;

  right: 70px;

  @include desktop {
    right: 90px;
  }

  button {
    height: 48px;

    * {
      font-size: 14px;
    }

    label {
      cursor: pointer;
    }

    i {
      width: 44px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 24px 24px;
    }

    span {
      color: white;

      &.shaka-current-selection-span {
        color: $color-player-menu-selection-bg;
      }
    }

    &:hover {
      background: $color-player-menu-selection-bg-hover;
    }
  }

  label {
    line-height: initial;
  }
}

.shaka-settings-menu {
  button {
    height: 45px;
  }

  [aria-selected='true'],
  [aria-selected='true']:hover {
    background: initial;
    background-size: 16px 16px;
    background-position: calc(100% - 12px) 50%;
    background-repeat: no-repeat;
    background-image: url(/svg/player/ic-tick.svg);

    &:hover {
      background-color: $color-player-menu-selection-bg-hover;
    }
  }

  .shaka-back-to-overflow-button {
    border-bottom: 1px solid $color-player-menu-selection-bg-hover;
  }
}

.shaka-back-to-overflow-button {
  position: relative;

  i {
    background-image: url(/svg/player/ic-back.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12px 24px;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 !important;
  }
}

// overflow menu - PiP

.shaka-pip-button {
  i {
    background-image: url(/svg/player/ic-mini-player.svg);
  }
}

// Cast button

.shaka-cast-button {
  i {
    background-image: url(/svg/player/ic-chromecast.svg);
  }
}

.shaka-resolution-button {
  i {
    background-image: url(/svg/player/ic-quality.svg);
  }
}

.shaka-caption-button {
  i {
    background-image: url(/svg/player/ic-subtitles.svg);
  }
}

.shaka-spinner-container {
  display: none;
}

// responsivity

@media (max-width: 860px) {
  .shaka-volume-bar-container,
  .shaka-mute-button {
    display: none;
  }
}

@media (max-width: 560px) {
  .shaka-skip-forward,
  .shaka-skip-backward {
    display: none;
  }
}

.loader {
  position: absolute;
  z-index: 1;
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(/svg/player/ic-loading.svg);
  background-repeat: no-repeat;
  background-size: 40px 40px;
  animation: 1s rotate ease-in-out infinite;
}

.shaka-play-pause-overlay,
.shaka-hide-controls-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 110px);
  width: 100%;
  background: transparent;
  z-index: 0;
  cursor: initial;
}

$size: 24px;
.shaka-close-button {
  width: $size;
  height: $size;
  background-image: url(/svg/player/ic-close.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: fixed;
  z-index: 5;
  top: 16px;
  right: 16px;
  text-indent: -9999px;

  @include desktop {
    width: $size * 2;
    height: $size * 2;
    top: 40px;
    right: 40px;
  }
}

.shaka-text-text-titles {
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px 0 0 40px;
  margin: 0;
  height: 100%;
  width: 33%;
  background: linear-gradient(to bottom, #000 0, rgba(0, 0, 0, 0) 15%);
  cursor: default;

  h1 {
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    color: white;
    margin-bottom: 8px;
    text-align: left !important;
  }

  h2 {
    font-size: 22px;
    line-height: 27px;
    color: $color-dark-grey;
    font-weight: normal;
    text-align: left !important;
  }
}

.shaka-video-container {
  .shaka-text-text-titles {
    width: 100%;
  }
}

.shaka-controls-button-panel {
  .shaka-text-text-titles {
    height: 100% !important;
  }
}

.shaka-floating-time {
  position: fixed;
  bottom: 95px;
  text-align: center;
  transform: translateX(-50%);
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  border-radius: 2px;
  background: $color-bg;
  color: $color-body-color;
  padding: 0 10px;
  margin: 0;
  opacity: 0;
  transition: 0.1s linear;
  transition-property: opacity;

  &.visible {
    opacity: 1;
  }
}

@import 'video_subtitles';
