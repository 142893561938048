@import 'tooltip';
@import 'original-badge';

.carousel-wrapper {
  left: -15px;
  width: 100vw;
  position: relative;
  font-size: 112.5%;
  height: 80vw;
  min-height: 600px;

  @include mobileLarge {
    min-height: 700px;
  }

  @include desktop {
    height: calc(100vw * 0.5625);
    max-height: 90vh;
    margin-bottom: -100px;
    min-height: initial;

    @include carouselMinHeight {
      height: 100vh;
      max-height: 100vh;
      margin-bottom: -80px;
    }
  }

  @include carouselAbsoluteMinHeight {
    margin-bottom: 0;
  }

  .thumb {
    display: block;
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-color: $color-body-bg;
    position: absolute;
    z-index: 0;

    @include carouselMaxWidth {
      height: 40vw;
    }

    &:before {
      content: '';
      display: block;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(1, 1, 24, 0) 0%, $color-body-bg 100%);

      @include desktop {
        height: 100%;
        background: linear-gradient(0deg, $color-body-bg 0%, rgba(0, 0, 0, 0) 23%),
          linear-gradient(90deg, $color-body-bg 0%, rgba($color-gradient-base, 0) 50%);
      }
    }

    @include desktop {
      height: 100%;
    }
  }

  h3 {
    order: 1;
    display: flex;
    flex-direction: column;
    margin: 8px 0;

    span {
      font-weight: bold;
      font-size: 100%;
      text-align: center;

      @include desktop {
        font-size: 200%;
        text-align: left;
      }
    }

    img {
      width: 72vw;
      height: auto;
      max-width: 520px;
      max-height: 100px;
      margin: 0 auto;

      @include desktop {
        width: auto;
        max-height: initial;
        height: 140px;
      }

      & + span {
        display: none;
      }
    }

    @include desktop {
      display: block;
      order: 2;
    }
  }

  .swiper-slide {
    background: $color-body-bg;

    @include desktop {
      background: initial;
    }

    &::marker {
      content: '';
      font-size: 0;
    }

    .links-wrapper {
      margin-bottom: 0;
      display: inline-block;
      order: 2;
      text-align: center;

      @include desktop {
        text-align: initial;
      }

      .original-link {
        display: none;

        @include desktop {
          display: inline-block;
        }
      }

      & + .original-link {
        @include desktop {
          display: none;
        }
      }
    }
  }

  .theatre-link {
    color: $color-dark-grey;
    display: inline-block;
  }

  .original-link {
    @extend %original;
  }

  .swiper-slide {
    .original-link {
      display: block;

      @include desktop {
        margin: 0 0 -4px 8px;
        display: inline-block;
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    z-index: 0;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 88px;

    @include desktop {
      margin-left: initial;
      align-items: initial;
      justify-content: initial;
      padding-top: 100px;
      padding-left: 30px;
      padding-bottom: initial;
    }

    @include desktopFull {
      padding-top: 10vw;

      @include carouselAbsoluteMinHeight {
        h3 {
          img {
            height: 27vh;
            width: auto;
          }
        }
      }

      @include carouselMinHeight {
        padding-top: 0;
        justify-content: center;
      }
    }

    p {
      order: 3;
      width: 540px;
      max-width: 80vw;
      margin: 0;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }

  .buttons {
    order: 4;
    margin-top: 31px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 576px;

    * + * {
      margin-top: 15px;
    }

    @include desktop {
      text-align: left;
      margin-top: 40px;
      background: transparent;
      width: initial;
      display: initial;
      max-width: initial;

      * + * {
        margin-top: 0;
        margin-left: 15px;
      }
    }

    // @include carouselMinHeight {
    //   margin-top: 2vh;
    // }
  }
}

.dramox-carousel {
  padding: 0;

  @include desktop {
    margin-bottom: 0;
  }
}

/* pagination */

.swiper-container-horizontal {
  .swiper-pagination {
    bottom: 60px;
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;

    @include desktop {
      position: absolute;
      left: initial !important;
      right: 35px;
      bottom: 130px !important;
      text-align: right !important;
    }
  }
}

/* pagination bullet */

.swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  position: relative;
  opacity: 1;
  background: none;
  outline: 0;

  &:only-child {
    display: none;
  }

  svg {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transform: scaleY(-1);

    circle {
      fill: transparent;
      stroke-width: 32;
    }
  }

  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 4px;
    left: 4px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #ffffff;
    opacity: 0.2;
  }
}

.swiper-pagination-bullet-active {
  transform: rotate(-90deg);
  border-radius: 8px;

  svg {
    display: block;

    circle {
      animation: arc-uncover 8.2s linear forwards;
    }
  }

  &:before {
    display: none;
  }
}

@keyframes arc-uncover {
  0% {
    stroke-dasharray: 100 100;
  }
  100% {
    stroke-dasharray: 0 100;
  }
}
