header {
  background: linear-gradient(180deg, $color-body-bg 0%, rgba($color-gradient-base, 0) 100%);

  min-height: 64px;
  &.fixed {
    &.slide-up {
      animation: slide-up 120ms;
    }

    &.slide-down {
      animation: slide-down 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  @include desktop {
    padding: 0;
    font-size: 0.8888888889em;
  }

  .header-content {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 64px;

    & > div {
      height: 100%;
      width: 64px;

      @include desktop {
        height: auto;
        width: auto;
        flex: 1;
        order: 2;
      }
    }
  }

  & > a {
    height: 100%;
    width: 64px;
    display: block;
  }

  .search {
    @include desktopMenu {
      display: none;
    }
  }

  .nav-toggle,
  .personal,
  .search {
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    position: relative;
    z-index: 3;
    transition: 0.25s ease-in-out;
    transition-property: border-color, opacity;
    opacity: 1;
  }

  .nav-toggle {
    background-image: url(/svg/ic-menu.svg);
    // background-image: url(/png/ic-menu-gift@2x.png);
    // background-size: 22px 14px;
  }

  .toggle-lang {
    position: absolute;
    background: #010118;
    text-indent: 0;
    border-radius: 3px;
    top: 3px;
    right: 0px;
    width: 17px;
    height: 11px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .search {
    background-image: url(/svg/ic-search.svg);
    opacity: 1;
    // transition: opacity 0.2s ease;
  }

  .personal {
    background-image: url(/svg/ic-avatar.svg);
    position: relative;

    @include desktop {
      background-position: 20px 50%;
      text-indent: 28px;
      width: auto;
      line-height: 64px;
    }
  }

  .register {
    display: none;

    @include desktop {
      display: block;
    }
  }

  .nav-toggle {
    width: 32px;
    height: 32px;
    display: block;
    z-index: 10000;
    position: absolute;
    right: 14px;
    top: 14px;

    @include desktopMenu {
      display: none;
    }

    & + .search {
      width: 64px;
      height: 64px;
      position: absolute;
      z-index: 1500;
      right: 48px;
    }
  }

  .logo {
    position: absolute;
    left: 20px;
    top: 11px;
    z-index: 1100;
    opacity: 1;
    transition: 0.25s ease-in-out;
    transition-property: opacity;
    opacity: 1;

    @include desktop {
      left: 30px;
    }
  }
}

.solid-header {
  background: #010117;
}

.logo {
  width: 120px;
  height: 41px;
  text-indent: -9999px;
  transition-property: width, opacity;

  &:before {
    content: '';
    display: block;
    background-image: url(/svg/ic-logo.svg);
    background-repeat: no-repeat;
    background-size: 120px 42px;
    background-position: 50% 50%;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.mobile-menu-open .toggle-lang {
  display: none;
}

.mobile-menu-open .languages {
  display: flex;
}

.languages {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-top: 1px solid $color-border;
  margin-bottom: 0;
  font-size: 88.88888889%;
  padding: 40px 0;
  line-height: 19px;
  margin-top: 24px;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 8px;
  }

  @include desktop {
    flex-direction: row;
    font-size: 77.77777778%;
    padding: 20px 0;
    line-height: 17px;
  }

  label {
    line-height: 24px;
    width: 0 !important;

    @include desktop {
      line-height: 48px;
    }
  }
}
