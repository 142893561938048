.donate-play {
  position: fixed;
  z-index: 10000;
  left: 40px;
  bottom: 104px;
  font-weight: bold;
  height: 96px;
  padding: 16px;
  padding-right: 20px;
  background: $color-donate-popup-bg;
  border-radius: $border-radius-global;
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
  }

  img {
    height: 64px;
    width: 64px;
    border-radius: 64px;
    margin-right: 16px;
  }

  a {
    color: $color-primary;

    &:hover {
      color: $color-text-anchor-hover;
    }
  }
}

.donate-temp-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: #fff !important;
    font-size: 28px !important;
    line-height: 34px !important;
    text-align: center;
    font-weight: bold !important;
  }

  a {
    margin-top: 24px;
  }
}
