@import 'styles/mixins';

.newsletterDialog {
  height: 470px;
  padding-top: 60px;

  iframe {
    visibility: hidden;
    width: calc(100%);
    border: 0;
    height: 370px;
  }

  @include mobileLarge {
    width: 500px;
    height: 540px;

    iframe {
      height: 450px;
    }
  }

  @include desktop {
    width: 660px;
  }

  &.loaded {
    iframe {
      visibility: visible;
    }
  }
}

:global(html.dialog-open) {
  .newsletterDialog {
    @include mobileLarge {
      left: calc(50% - 250px);
    }

    @include desktop {
      left: calc(50% - 330px);
    }
  }
}
