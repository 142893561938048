.stripe-card-container {
  position: relative;
  margin: 0;
}

.stripe-card {
  height: 50px;
  padding: 14px 15px;
  background-color: $color-input-bg;
  border-radius: $border-radius-global;
  transition: 0.25s ease-in-out;
  border: 1px solid transparent;

  & + span {
    display: none;
  }

  &.error {
    border-color: $color-danger !important;

    & + span {
      color: $color-danger;
      display: block;
      line-height: 1.4;
      margin-top: 10px;
    }
  }

  &.focus {
    background-color: $color-input-focus-bg;
    border-color: $color-input-focus-border;
  }
}

.stripe-disclaimer {
  color: $color-dark-grey;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 88.88888889%;
  margin: 25px 0 25px 0;

  a {
    text-indent: -9999px;
    display: inline-block;
    margin-left: 5px;
    width: 38px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/svg/ic-stripe-logo.svg);
  }
}

.stripe-benefit {
  display: inline-block;
  width: 82px;
  margin-left: 3px;
}

.info-label {
  position: relative;
}

.info-ico {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(/svg/ic-info-circle.svg);
  background-size: contain;
}

.card-info-ico {
  position: absolute;
  top: 16px;
  z-index: 30;
  left: calc(100% - 16px);
  height: 16px;
  width: 16px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
