@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn {
  height: 50px;
  line-height: 48px;
  font-size: 0.8888888889em;
  padding: 0 20px;
  font-weight: bold;
  border-radius: $border-radius-global;

  &.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.7 !important;
  }

  span {
    font-weight: normal;
  }

  &-no-events {
    pointer-events: none;
  }

  &-play {
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-image: url(/svg/ic-play.svg);
      margin-right: 4px;
      margin-bottom: -5px;
    }
  }

  &-outline-info {
    &:hover {
      border-color: $color-body-color;
      background-color: transparent;
    }
  }

  &-warning {
    border: 0;
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-image: url(/svg/ic-loading.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
      margin-right: 8px;
      margin-bottom: 0;
      animation: 1s rotate ease-in-out infinite;
    }
  }
}

.btn-cancel {
  background: #ffffff;
  border: 1px solid $color-border-btn;
}

.btn-inactive {
  background-color: $color-warning;
  color: #ffffff;
  user-select: none;
  pointer-events: none;
}

textarea.required,
input[type='text'],
input[type='password'] {
  height: 50px;
  font-size: 18px;
  padding: 0 15px;
  border: 0;
  border-radius: $border-radius-global;
  outline: 0;
  border: 1px solid transparent;
  background-color: $color-input-bg;
  transition: 0.15s ease-in-out;
  color: $color-input-color;

  &:disabled {
    color: $color-input-disabled-color;
  }

  & + span {
    color: $color-danger;
    display: none;
  }

  &::placeholder {
    color: $color-input-placeholder;
  }

  &:focus {
    background-color: $color-input-focus-bg;
    border-color: $color-input-focus-border;
  }

  &.error {
    border-color: $color-danger !important;

    & + span {
      display: block;
      line-height: 1.5;
      padding-top: 10px;
    }
  }

  &.half {
    width: 50%;
  }
}

.standalone-error {
  color: $color-danger;
  display: block;
  line-height: 1.5;
  padding-top: 10px;
  font-size: 0.8888888889em;
}

label {
  color: $color-dark-grey;
  font-size: 0.8888888889em;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  line-height: 48px;

  &.server-error {
    margin-top: 16px;
    min-height: 32px;
    line-height: 1.5;
    color: $color-danger;
    display: none;
  }
}

form {
  display: flex;
  flex-direction: column;

  &.display-server-errors {
    .server-error {
      display: block;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: $color-body-color;
    }
  }

  .btn {
    display: block;
  }

  label {
    & + label {
      margin-top: 12px;
    }

    & + .btn {
      margin-top: 24px;
    }
  }

  &.loading {
    pointer-events: none;
    user-select: none;

    .btn-primary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(/svg/ic-loading.svg);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        margin-right: 8px;
        animation: 1s rotate ease-in-out infinite;
      }
    }

    .btn,
    input,
    select {
      pointer-events: none;
      user-select: none;
    }

    input,
    select {
      opacity: 0.7;
    }
  }
}

input[type='radio'] {
  position: absolute;
  left: -9999px;

  &:checked {
    & + .radio {
      border-color: $color-primary;
      background-color: rgba($color-primary, 0.32);
    }
  }

  &:not(:checked) {
    & + .radio {
      &:hover {
        background-color: rgba($color-input-bg, 0.8);
      }
    }
  }

  & + .radio {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    line-height: 1.2;
    color: $color-body-color;
    background-color: $color-input-bg;
    border-radius: $border-radius-global;
    border: 2px solid transparent;
    transition: 0.25s ease-in-out;
    transition-property: border-color, background-color;
    text-align: center;

    strong {
      display: block;
    }
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  user-select: none;
  font-size: inherit;
  color: $color-body-color;

  & + .checkbox {
    margin-top: 0;
  }

  input {
    position: absolute;
    left: -9999px;

    &:checked {
      & ~ span {
        &:after {
          display: block;
        }
      }
    }

    &:focus {
      & ~ span {
        box-shadow: 0 0 0 3px $color-input-focus-border;
      }
    }
  }

  span {
    width: 24px;
    height: 24px;
    border-radius: $border-radius-global;
    background-color: $color-dark;
    display: block;
    margin-right: 15px;
    position: relative;
    transition: 0.15s ease-in-out;
    transition-property: box-shadow;

    &:after {
      display: none;
      content: '';
      background-image: url(/svg/ic-checkbox-active.svg);
      background-size: 24px 24px;
      background-position: 50% 50%;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.radios {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .radio {
    margin-top: 0;
    flex: 1;

    img {
      width: 20px;
      height: 20px;
    }

    & ~ .radio {
      margin-left: 10px;
    }
  }

  label:not(.radio) {
    flex-basis: 100%;
  }

  .custom-amount {
    & ~ label:not(.radio, .email) {
      display: none;
    }

    &:checked {
      & ~ label:not(.radio, .email) {
        display: flex;
      }
    }
  }
}

select {
  appearance: none;
  font-size: 18px;
  background-image: url(/svg/ic-select-chevron.svg);
  background-position: calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-size: 12px 6px;
  background-color: $color-input-bg;
  color: $color-input-color;
  height: 50px;
  line-height: 50px;
  border-radius: $border-radius-global;
  border: 0;
  padding-left: 15px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border: 1px solid transparent;
  width: 100%;

  & + span {
    color: $color-danger;
    display: none;
  }

  &.error {
    border-color: $color-danger;

    & + span {
      display: block;
    }
  }

  option {
    background: $color-body-bg;
    color: $color-input-color;
  }

  &.tiny {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    width: initial;
    border: 0;
    background-image: url(/svg/ic-chevron-menu.svg);
    background-position: 100% 50%;
    background-size: 16px 16px;
    border-radius: 0;
    width: 130px;
    background-color: rgba($color: #010118, $alpha: 0.7);

    option {
      background: initial;
    }
  }
}
