@import '../styles/colors';

.notificationList {
  position: fixed;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-radius: 60px;
  background: #fff;
  color: #0b1735;
  border: 0;
  margin-bottom: 10px;
  text-align: center;
  padding: 12px 32px;
  min-width: 280px;

  strong {
    font-weight: normal;
  }

  strong + p {
    margin-top: 2px;
  }

  strong,
  p {
    color: #0b1735;
  }

  &.notificationDanger {
    strong,
    p {
      color: $color-primary;
    }
  }
}
