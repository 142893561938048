// playlist for same genres/casts
.category-playlist {
  position: relative;
  margin: 0 -15px;
  padding: 0 15px 40px 15px;
  background-image: linear-gradient(rgba(13, 19, 33, 0), #0d1321);

  @include carouselMaxWidth {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.first {
    margin-top: 96px;

    @include desktopFull() {
      margin-top: 155px;
    }
  }

  a {
    color: $color-body-color;
  }

  .play-list-wrapper {
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
      max-width: 910px;
    }

    @include desktopFull {
      max-width: 1200px;
    }

    .empty-list {
      max-width: 370px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .icon {
        width: 70px;
        border-radius: 70px;
        height: 70px;
        background-color: rgba(72, 72, 72, 0.4);
        background-image: url(/svg/ic-icon-empty-list.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      h2 {
        margin-top: 32px;
        font-weight: 700;
        font-size: 28px;
        line-height: 1.2;
        height: auto;
      }

      p {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 22px;
        padding: 0 18px;
      }

      a {
        margin-top: 40px;
        font-weight: 700;
        color: $color-primary;

        &:hover {
          color: $color-text-anchor-hover;
        }
      }

      @include desktop {
        margin-bottom: 178px;
      }
    }
  }

  .with-sort {
    .sort-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: column;
      h1 {
        margin: 0;
      }

      .sort-option {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        padding-left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        border-radius: 4px;
        transition: border 0.3s ease;

        &:hover {
          border: 1px solid #ffffff;
        }

        span {
          flex-shrink: 0;
          display: inline-block;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #fff;

          &:first-child {
            color: rgba(255, 255, 255, 0.7);
            // margin-right: 8px;
          }

          // &:last-child {
          //   position: relative;
          //   display: flex;
          //   align-items: center;
          //   cursor: pointer;

          //   &::after {
          //     display: inline-block;
          //     content: '';
          //     background-image: url(/svg/ic-chevron-red.svg);
          //     width: 24px;
          //     height: 24px;
          //     background-repeat: no-repeat;
          //     transform: rotate(90deg);
          //     transition: transform 0.22s ease;
          //   }
          // }
        }

        .sort-select {
          background-image: url(/svg/ic-chevron-red-down.svg);
          background-size: 24px 24px;
          background-position: calc(100% - 5px) 50%;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          background-color: transparent;
          padding-right: 30px;
        }
      }

      .rotate {
        span:last-child::after {
          transform: rorate(-180deg);
        }
      }

      @include mobileLarge {
        flex-direction: row;
      }
    }
  }

  .play-list {
    height: initial;
    position: relative;
    margin-right: 0;
    display: block;

    @include carouselMaxWidth {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -30px;
    }

    @include desktopFull {
      margin-right: -30px;
    }

    li {
      width: 100%;
      position: relative;

      @include carouselMaxWidth {
        max-width: calc(50% - 30px);
        margin-top: 0;
        margin-left: 30px;

        &:nth-child(2n) {
          margin-left: 0;
        }

        & + li {
          & ~ li {
            margin-top: 15px;
          }
        }
      }

      @include desktop {
        margin-right: 0;

        & + li {
          & ~ li {
            margin-top: 30px;
          }
        }
      }

      @include desktopFull {
        max-width: 270px;

        &:nth-child(2n) {
          margin-left: 30px;
        }

        & + li {
          & ~ li {
            margin-top: 0;
          }
        }

        &:nth-child(4) {
          & ~ li {
            margin-top: 30px;
          }
        }
      }

      & + li {
        margin-top: 15px;

        @include carouselMaxWidth {
          margin-top: 0;
        }
      }

      &:hover {
        img {
          opacity: 0.72;
        }

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      a {
        display: flex !important;
        flex-direction: row;
        align-items: center;

        @include desktopFull {
          flex-direction: column;
          align-items: flex-start;
        }

        div {
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 0;
          }
        }
      }

      &:after {
        width: 123px;
        height: 75px;

        @include desktop {
          width: 218px;
          height: 129px;
        }

        @include desktopFull {
          width: 284px;
          height: 166px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 109px;
      margin: 0 15px 0 0;

      @include desktop {
        width: 204px;
      }

      @include desktopFull {
        width: 270px;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }

  h1 {
    margin-bottom: 23px;

    @include desktopFull() {
      font-size: 222.22222222%;
      margin-bottom: 48px;
    }
  }
}

.img-placeholder-category-playlist {
  width: 100%;
  height: 126px;

  @include desktop {
    width: 256px;
    height: 144px;
  }
}
