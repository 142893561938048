.author-detail {
  display: flex;
  flex-direction: column;

  .info-wrapper {
    padding-top: 100px;
    margin: 0 auto;
    max-width: 540px;
    text-align: center;

    @include desktopFull {
      max-width: 1070px;
      text-align: initial;
      display: flex;
      flex-direction: row;
      padding-bottom: 56px;
      padding-top: 148px;
      width: 100%;
    }

    h1 {
      margin-bottom: 22px;

      @include desktopFull {
        font-size: 222.22222222%;
        margin-bottom: 16px;
      }
    }

    p {
      text-align: initial;
    }

    .image-placeholder {
      background-color: $color-photo-bg;
      border-radius: 120px;
      width: 120px;
      height: 120px;
      margin-bottom: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 222.22222222%;
      font-weight: bold;
      margin: 0 auto;
      margin-bottom: 36px;
      position: relative;

      @include desktopFull {
        margin-right: 30px;
        width: 170px;
        height: 170px;
        min-width: 170px;
        min-height: 170px;
        border-radius: 170px;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }

  .text-wrapper {
    flex: 1;
  }
}

.theater-detail {
  .buttons {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;

    .btn:first-child {
      margin-bottom: 15px;
    }

    @include desktopFull {
      flex-direction: row;
      margin-bottom: 48px;

      .btn:first-child {
        margin-bottom: 0;
        margin-right: 15px;
      }
    }
  }

  .text-wrapper {
    flex: 1;
  }
}
