.fade-container {
  max-width: 720px;
  width: 100vw;
  height: 300px;
  overflow: hidden;

  @include desktop {
    height: 405px;
  }

  .swiper-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .swiper-slide {
    height: 100%;
    width: 100%;
    background-size: cover;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 180px;
      bottom: 0;
      background-image: linear-gradient($color-image-gradient, $color-body-bg);

      @include desktop {
        height: 100%;
        background-image: radial-gradient(closest-side, $color-image-gradient, $color-body-bg);
      }
    }

    &-active {
      img {
        transform: scale(1.07);
      }
    }

    &.fade-slide {
      div {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
    }
  }

  img {
    opacity: 0.7;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    transform: scale(1);
    transition: 6s transform ease-in-out;
  }
}
