@keyframes dialogSlide {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes dialogSlideDesktop {
  0% {
    transform: translateY(calc(-50% - 20px));
    opacity: 0;
  }

  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes dialogSlideCookieMobile {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.dialog {
  position: fixed;
  border-radius: $border-radius-big;
  left: -10000px;
  z-index: 1032;
  padding: 32px;
  background: $color-body-color;
  color: $color-body-bg;
  text-align: center;
  bottom: 35px;

  .close {
    height: 24px;
    width: 24px;
    display: block;
    position: absolute !important;
    right: 30px;
    top: 18px;
    background-image: url(/svg/ic-close-dark.svg);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    text-indent: -9999px;
  }

  @include mobileLarge {
    width: 375px;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
  }

  & > * {
    margin-bottom: 24px;
    z-index: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not(.btn) {
    color: $color-primary;
    font-weight: bold;
    position: relative;

    &:hover {
      color: $color-text-anchor-hover;
    }
  }

  h2 {
    font-size: 111.11111111%;
    font-weight: bold;
    z-index: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  .btn {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &.list-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wrapper {
      list-style: 0;
      padding-left: 0;
      width: 100%;
      overflow-y: scroll;

      h3 {
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color: #010117;
        opacity: 0.7;
        padding: 0 20px;

        &:first-of-type {
          margin-top: 24px;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;

        li {
          height: 60px;

          a {
            display: flex;
            height: 100%;
            width: 100%;
            padding: 0 15px;
            align-items: center;
            color: $color-body-bg;
            font-weight: normal;

            &:hover {
              background: rgba($color-dark, 0.08);
            }
          }

          .image {
            display: inline-block;
            height: 36px;
            width: 36px;
            border-radius: 18px;
            margin-right: 15px;
          }

          span.image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $color-body-color;
            background: $color-dark;
            font-size: 77.77777778%;
            text-align: center;
          }
        }
      }
    }
  }

  &.text-content {
    padding: 0;
    width: 630px;
    height: 60vh;

    & > div {
      height: calc(100% - 60px);
    }

    h2 {
      height: 60px;
      line-height: 60px;
      padding: 0 30px;
      text-align: left;
      margin: 0;
      position: relative;
      width: 100%;
      border-bottom: 1px solid $color-border-inverted;
    }

    p {
      text-align: left;
      white-space: pre-line;
      margin: 30px;
    }

    .text-wrapper {
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: $border-radius-secondary;
        margin-right: 3px;
        background: rgba(0, 0, 0, 0.3);
      }
      overflow-y: auto;
      height: 100%;
    }
  }

  &.residency,
  &.subscription {
    padding-top: 52px;

    h2 {
      &:before {
        width: 45px;
        height: 45px;
        content: '';
        display: block;
        background-image: url(/svg/ic-czech-circle.svg);
        background-size: contain;
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.subscription,
  &.promoPreview {
    .btn {
      color: $color-body-color;
      background: linear-gradient(269.95deg, #c99d38 43.27%, #e3c14d 99.96%);
      border: 0;
    }
  }

  &.subscription {
    h2 {
      &:before {
        width: 50px;
        height: 50px;
        top: -20px;
        background-image: url(/svg/ic-logo-gold-circle.svg);
      }
    }
  }
}

html.dialog-open {
  .dialog {
    left: 15px;
    right: 15px;
    animation: dialogSlide 0.25s ease-in-out forwards;

    @include mobileLarge {
      left: calc(50% - 375px / 2);
      right: initial;
      animation-name: dialogSlideDesktop;
    }

    &.text-content {
      left: calc(50% - 630px / 2);
    }

    .delete-buttons {
      display: flex;
      justify-content: flex-end;

      .btn {
        width: unset;

        &-cancel {
          margin-right: 12px;
        }
      }
    }
  }

  body {
    overflow: hidden;
  }

  &.allow-scrolling {
    body {
      overflow: initial;
    }
  }

  .delete-dialog {
    & > h2,
    p {
      text-align: left;
    }

    & > p {
      line-height: 22px;
    }
  }

  .delete-dialog-promo {
    & > h2 {
      text-align: center;
    }

    .get-more {
      p {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
      }

      background: #fff;
      background-clip: padding-box;
      border: 3px solid transparent;
      border-radius: 8px;
      position: relative;
      box-sizing: border-box;
      margin: auto;
      z-index: auto;
      padding: 16px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(270deg, #c99d38 0%, #e3c14d 100%);
      }

      a {
        display: inline-block;
        margin-top: 12px;
        background: linear-gradient(270deg, #c99d38 0%, #e3c14d 100%);
        border-radius: 4px;
        color: #fff;
        padding: 7.5px 16px;
        transition: opacity 0.2s ease;
        opacity: 1;

        &:hover {
          color: #fff !important;
          background: linear-gradient(270deg, #c99d38 0%, #e3c14d 70%);
        }
      }
    }

    .or-string {
      margin-top: 16px !important;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #010117;
      margin-bottom: 0;

      &::before {
        content: '';
        position: absolute;
        background: rgba(1, 1, 23, 0.16);
        height: 1px;
        width: 38%;
        left: 0;
        top: 14px;
      }

      &::after {
        content: '';
        position: absolute;
        background: rgba(1, 1, 23, 0.16);
        height: 1px;
        width: 38%;
        right: 0;
        top: 14px;
      }
    }

    .remove-text {
      margin-top: 16px;
    }

    .delete-buttons {
      justify-content: space-between;

      .cancel-sub {
        max-width: 211px;
        width: 100%;
      }
    }
  }

  .cookies {
    padding: 0;
    padding-top: 20px;
    max-width: 770px;
    left: 0;
    right: 0;
    margin-left: 18px;
    width: auto;
    margin-right: 18px;

    @include popupWidth {
      margin: auto;
      width: 100%;
    }

    &-container {
      margin-top: 48px;
      text-align: left;
      overflow-y: scroll;
      margin-bottom: 0;
      position: relative;

      @include mobileLarge {
        max-height: 350px;
      }

      > * {
        padding-left: 20px;
        padding-right: 20px;
      }

      ul {
        padding-left: 50px;
      }
    }

    &-logo {
      display: inline-block;
      background-image: url(/svg/ic-logo-black.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 83px;
      height: 29px;
      position: absolute;
      left: 20px;
      top: 20px;
    }

    &-btns {
      height: 80px;
      align-items: center;
      display: flex;
      justify-content: center;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);

      @include desktop {
        justify-content: flex-end;

        a {
          &:last-child {
            margin-right: 20px;
          }
        }
      }

      a {
        flex: 0;
        white-space: nowrap;
        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }

  .cookies-bar {
    &:before,
    &:after {
      display: none;
    }

    .buttons {
      display: flex;
      flex-direction: column;

      & > * {
        & + * {
          margin-top: 20px;
        }
      }
    }

    @include mobileLarge {
      padding: 20px;
      animation-name: dialogSlideCookieMobile;
      position: fixed;
      right: 20px;
      bottom: 18px;
      left: initial;
      top: initial;
      text-align: left;
      transform: initial;
      min-width: 340px;

      p {
        font-size: 0.8888888889em;
        line-height: 19px;
      }

      .buttons {
        display: inline-flex;
        flex-direction: row;
        float: right;

        & > * {
          width: auto;

          & + * {
            margin-left: 12px;
            margin-top: 0;
          }
        }
      }
    }
  }
}
