@import 'styles/mixins';

.buttons {
  & > span {
    width: 100%;
    display: inline-block;
  }

  @include desktop {
    & > span {
      width: 97px;

      & + span {
        width: 160px;
      }
    }
  }
}
