@import "../../styles/animations";

.loading {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/svg/ic-loading.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    animation: rotate 1s ease-in-out infinite;
  }
}

.medium::before {
  width: 32px;
  height: 32px;
  background-size: 32px;
}
