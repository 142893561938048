@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/vars';
@import '../../styles/animations';

.sub {
  font-size: 88.88888889%;
  color: #deae3f;
  height: 35px;
  line-height: 35px;
  padding: 0 12px;
  border: 1px solid #deae3f;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 24px;
  width: auto;
}

.subContainer {
  text-align: initial;
  align-items: flex-start !important;
  padding-top: 47px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 80px;

  button {
    align-self: flex-start;
  }

  &:before {
    content: '';
    display: block;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: -1;
    right: -100vw;
    left: -100vw;
    background: linear-gradient(rgba($color-form-gradient-base, 0.9) 0.02%, rgba($color-form-gradient-base, 0) 99.97%);
    border-top: 1px solid hsla(0, 0%, 100%, 0.12);
  }

  @include desktop {
    padding-left: 0;
  }

  @include desktopFull {
    padding-top: 80px;
    max-width: 770px;
    width: 100%;
  }

  h2 {
    font-size: 88.88888889%;
    color: $color-gold;
    height: 35px;
    line-height: 35px;
    padding: 0 12px;
    border: 1px solid $color-gold;
    border-radius: $border-radius-global;
    display: inline-block;
    margin-bottom: 24px;
    width: auto;
  }

  h3 {
    font-size: 155.55555556%;
    font-weight: bold;
    margin: 0;
  }

  h4 {
    font-size: 111.11111111%;
    font-weight: bold;
    margin-top: 48px;
  }

  p {
    margin-top: 16px;
    margin-bottom: 32px;
    color: $color-dark-grey;
  }
}

.forms {
  max-width: 100%;
  width: 770px;
  margin: 0 auto;
  position: relative;
  padding-top: 80px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: -1;
    right: -100vw;
    left: -100vw;
    border-top: 1px solid hsla(0, 0%, 100%, 0.12);
  }
}

// .account {
//   .infoWrapper {
//     padding-top: 100px;
//     margin: 0 auto;
//     max-width: 540px;
//     text-align: center;

//     @include desktopFull {
//       max-width: 770px;
//       text-align: initial;
//       padding-top: 148px;
//     }

//     .info {
//       @include desktopFull {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         margin-bottom: 0;
//         padding-bottom: 100px;
//         position: relative;

//         &:after {
//           content: '';
//           display: block;
//           height: 1px;
//           background-color: $color-border;
//           position: absolute;
//           bottom: 0;
//           left: -250vw;
//           width: 500vw;
//         }
//       }
//     }

//     .imagePlaceholder {
//       background-color: $color-primary;
//       border-radius: 120px;
//       width: 120px;
//       height: 120px;
//       margin-bottom: 36px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       font-size: 155.55555556%;
//       font-weight: bold;
//       margin: 0 auto;
//       margin-bottom: 36px;
//       position: relative;
//       text-transform: uppercase;

//       @include desktopFull {
//         width: 70px;
//         height: 70px;
//         min-width: 70px;
//         min-height: 70px;
//         border-radius: 70px;
//         margin: 0 32px 0 0;
//       }
//     }
//   }

//   .buttons {
//     display: flex;
//     flex-direction: row;

//     & > * {
//       margin-right: 12px;
//     }
//   }

//   .textWrapper {
//     margin-bottom: 80px;

//     @include desktopFull {
//       margin-bottom: 0;
//     }

//     h1 {
//       margin-bottom: 2px;

//       @include desktopFull {
//         font-size: 222.22222222%;
//         margin-bottom: 8px;
//         margin-top: -8px;
//       }
//     }

//     p {
//       color: $color-dark-grey;
//       margin-bottom: 0;
//     }
//   }

//   .forms {
//     @include desktopFull {
//       display: flex;
//       flex-direction: row;
//       flex-wrap: wrap;
//     }
//   }

//   .formSubscription {
//     text-align: initial;
//     align-items: flex-start !important;
//     padding-top: 47px;

//     @include desktop {
//       margin-left: 0 !important;
//     }

//     &:before {
//       background: linear-gradient(
//         rgba($color-form-gradient-base, 0.9) 0.02%,
//         rgba($color-form-gradient-base, 0) 99.97%
//       );
//       border: 0;
//     }

//     @include desktop {
//       padding-left: 0;
//     }

//     @include desktopFull {
//       padding-top: 80px;
//       max-width: 770px;
//       width: 100%;
//     }

//     h2 {
//       font-size: 88.88888889%;
//       color: $color-gold;
//       height: 35px;
//       line-height: 35px;
//       padding: 0 12px;
//       border: 1px solid $color-gold;
//       border-radius: $border-radius-global;
//       display: inline-block;
//       margin-bottom: 24px;
//       width: auto;
//     }

//     h3 {
//       font-size: 155.55555556%;
//       font-weight: bold;
//       margin: 0;
//     }

//     h4 {
//       font-size: 111.11111111%;
//       font-weight: bold;
//       margin-top: 48px;
//     }

//     p {
//       margin-top: 16px;
//       margin-bottom: 32px;
//       color: $color-dark-grey;
//     }
//   }

//   form,
//   .form {
//     text-align: initial;
//     align-items: flex-end;
//     padding-top: 48px;
//     padding-bottom: 80px;
//     padding-left: 15px;
//     padding-right: 15px;
//     margin-left: -15px;
//     margin-right: -15px;
//     position: relative;

//     @include desktop {
//       padding-right: 0;
//       padding-left: 0;
//       margin-left: 0;
//       margin-right: 0;
//     }

//     &:before {
//       content: '';
//       display: block;
//       height: 100%;
//       top: 0;
//       position: absolute;
//       z-index: -1;
//       right: -100vw;
//       left: -100vw;
//       border-top: 1px solid $color-border;
//     }

//     // & + form,
//     // & + .form {
//     //   &:before {
//     //     background: linear-gradient(
//     //       rgba($color-form-gradient-base, 0.9) 0.02%,
//     //       rgba($color-form-gradient-base, 0) 99.97%
//     //     );
//     //   }
//     // }

//     label {
//       width: 100%;
//     }

//     label + .btn {
//       margin-top: 40px;
//     }

//     label + p {
//       margin-top: 32px;
//     }

//     h2 {
//       width: 100%;
//       margin-bottom: 30px;
//       font-size: 155.55555556%;
//     }

//     p {
//       margin-bottom: 20px;
//       width: 100%;
//     }

//     strong {
//       font-size: 111.11111111%;
//       line-height: 1.2;
//     }

//     @include desktopFull {
//       width: 370px;
//       padding-top: 80px;
//       background: none !important;
//       border: 0;

//       &:nth-child(2n) {
//         margin-left: 30px;
//       }
//     }
//   }

//   .deleteUser {
//     @include desktop {
//       margin-left: 0 !important;
//     }

//     &:before {
//       background: linear-gradient(
//         rgba($color-form-gradient-base, 0) 0.02%,
//         rgba($color-form-gradient-base, 0.9) 99.97%
//       );
//     }

//     @include desktopFull {
//       width: 770px;
//     }

//     p {
//       opacity: 0.7;
//       margin-bottom: 40px;
//     }
//   }

//   .form {
//     border-bottom: 1px solid $color-border;

//     @include desktopFull {
//       border-bottom: 0;
//     }
//   }

//   time {
//     color: $color-dark-grey;
//     font-size: 88.88888889%;
//   }

//   // a {
//   //   font-size: inherit;
//   //   font-weight: bold;
//   //   color: $color-primary;
//   //   line-height: 1.25;

//   //   &:hover {
//   //     color: $color-text-anchor-hover;
//   //   }
//   // }

//   & + footer {
//     margin-top: 30px;
//   }

//   .cardWrapper {
//     position: relative;
//     width: 100%;
//     opacity: 1;
//     transition: 0.25s ease-in-out;
//     transition-property: opacity;
//     z-index: 1;
//     margin-top: 0;
//     margin-bottom: 0;
//     visibility: visible;

//     &.elHidden {
//       margin-top: -48px;
//       margin-bottom: -48px;
//       opacity: 0;
//       z-index: 0;
//       visibility: hidden;
//     }

//     @include desktopFull {
//       max-width: 370px;
//     }

//     .btn {
//       opacity: 1;
//       z-index: 1;

//       @include desktopFull {
//         position: absolute;
//         right: 0;
//         top: 0;
//         transform: translateX(calc(100% + 15px));
//         margin: 0;
//       }
//     }
//   }

//   .addCardHidden {
//     display: none;
//   }
// }
