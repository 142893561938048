@import '../../styles/mixins';

.imgPlaceholder {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  border-radius: inherit;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #484848;
    opacity: 0.4;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 9px;
  }
}
