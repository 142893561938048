@import '../../styles/colors';
@import '../../styles/mixins';

.faqList {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border: 0;
    border-color: $color-border;
    border-top-width: 1px;
    border-style: solid;

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  h5 {
    min-height: 64px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    padding-left: 39px;
    font-size: 111.11111111%;
    margin: 0;
    position: relative;
    transition: 0.25s ease-in-out;
    transition-property: color;
    // user-select: none;
    text-align: left;

    &:hover {
      color: rgba($color-body-color, 0.7);
    }

    &:before,
    &:after {
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      content: '';
      display: inline-block;
      background-image: url(/svg/ic-plus.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      opacity: 1;
      transition: 0.25s ease-in-out;
      transition-property: opacity, transform;
      transform-origin: center;
      transform: rotate(-90deg);
    }

    &:after {
      background-image: url(/svg/ic-minus.svg);
      opacity: 0;
    }

    &.expanded {
      &:before,
      &:after {
        transform: rotate(0);
      }

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  section {
    div {
      margin: 0;
      margin-top: 15px;
      color: $color-body-color;
      line-height: initial;
      padding-bottom: 24px;
      white-space: pre-line;

      @include mobileLarge {
        margin-top: 0;
      }
    }
  }
}
