// caregory boxes
.category-section-full {
  position: relative;
  margin: 0 -15px;
  padding: 0 15px 40px 15px;
  background-image: linear-gradient(rgba(13, 19, 33, 0), #0d1321);

  @include carouselMaxWidth {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.first {
    margin-top: 55px;

    &:before {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      background-color: $color-border;
    }
  }

  a {
    color: $color-body-color;
    outline: 0;
  }

  .play-list-wrapper {
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
      max-width: 910px;
    }

    @include desktopFull {
      max-width: 1200px;
    }
  }

  .play-list {
    height: initial;
    position: relative;
    margin-right: 0;
    display: block;

    @include carouselMaxWidth {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -30px;
    }

    @include desktopFull {
      margin-right: -30px;
    }

    li {
      width: 100%;
      position: relative;

      @include carouselMaxWidth {
        max-width: calc(50% - 30px);
        margin-top: 0;
        margin-left: 30px;

        &:nth-child(2n) {
          margin-left: 0;
        }

        & + li {
          & ~ li {
            margin-top: 15px;
          }
        }
      }

      @include desktop {
        margin-right: 0;

        & + li {
          & ~ li {
            margin-top: 30px;
          }
        }
      }

      @include desktopFull {
        max-width: calc(270px);

        &:nth-child(2n) {
          margin-left: 30px;
        }

        & + li {
          & ~ li {
            margin-top: 0;
          }
        }

        &:nth-child(4) {
          & ~ li {
            margin-top: 30px;
          }
        }
      }

      & + li {
        margin-top: 15px;

        @include carouselMaxWidth {
          margin-top: 0;
        }
      }

      a {
        display: flex !important;
        flex-direction: row;
        align-items: center;

        @include desktopFull {
          flex-direction: column;
          align-items: flex-start;
        }

        div {
          display: flex;
          flex-direction: column;
          p {
            margin-bottom: 0;
          }
        }
      }

      &:after {
        width: 123px;
        height: 75px;

        @include desktop {
          width: 218px;
          height: 129px;
        }

        @include desktopFull {
          width: 284px;
          height: 166px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 109px;
      height: 61px;
      margin: 0 15px 0 0;

      @include desktop {
        width: 204px;
        height: auto;
      }

      @include desktopFull {
        width: 270px;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }

  h2 {
    margin-bottom: 15px;
  }
}

.image-wrapper-for-playlist {
  width: 109px;
  height: 61px;
  margin-right: 15px;

  @include desktop {
    width: 204px;
    height: 114px;
    margin-right: 15px;
  }

  @include desktopFull {
    width: 270px;
    height: 151px;
    margin-bottom: 12px;
  }
}
