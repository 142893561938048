// Shaka subtitles

.shaka-text-container {
  bottom: 40px;
  border: 1px solid transparent;

  span {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 10px;
  }

  * {
    font-size: 1.944444444vw;
    line-height: 2.361111111vw;

    @media (orientation: portrait) {
      font-size: 2.5vh;
      line-height: 3vh;
    }

    @media (min-width: 1920px) {
      font-size: 37px;
      line-height: 46.25px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 17.5px;
    }
  }
}

// safari native subtitles

video::-webkit-media-text-track-display-backdrop {
  overflow: visible !important;
}

video::-webkit-media-text-track-display {
  overflow: visible !important;
  -webkit-box-sizing: border-box;
}

video::-webkit-media-text-track-container {
  overflow: visible !important;
  transform: translateY(0);
  position: absolute;
}

.shaka-controls-container[shown='true'] {
  video::-webkit-media-text-track-container {
    transform: translateY(-110px);
  }
}

// basic
video::cue {
  font-size: 1.944444444vw;
  // line-height: 2vw;
}

video::-webkit-media-text-track-container {
  overflow: visible !important;
  position: absolute;
}

// fix font-size on portrait
@media (orientation: portrait) {
  video::cue {
    font-size: 20px;
    // line-height: 34px;
  }

  video::-webkit-media-text-track-container {
    transform: translateY(0) !important;
  }
}

// fix font size for screens larger than full hd
@media (min-width: 1920px) and (orientation: landscape) {
  video::cue {
    font-size: 37px;
    line-height: 46.25px;
  }
}

// very small Apple devices
@media (max-width: 480px) and (orientation: landscape) {
  video::cue {
    font-size: 14px;
    line-height: 17.5px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  video::cue {
    font-size: 20px;
    line-height: 34px;
  }

  // video::-webkit-media-text-track-container {
  //   transform: translateY(0) !important;
  // }
}

@media (max-height: 420px) and (orientation: landscape) {
  // video::-webkit-media-text-track-container {
  //   transform: translateY(-5vw) !important;
  // }
}

.ios-fullscreen {
  video::cue {
    font-size: 36px;
    line-height: initial;
  }
}
