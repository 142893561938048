@keyframes enlarge {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.07);
  }
}

@keyframes enlargeAndShift {
  0% {
    transform: translateX(-50%) scale(1);
  }
  100% {
    transform: translateX(-50%) scale(1.07);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  14% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes progressShow {
  0% {
    transform: translateY(calc(-100% - 72px));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes fromHalfOpacityToFull {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
