@mixin portraitSection($width, $height) {
  .control {
    &:after {
      top: 45% !important;
    }
  }

  .scroll-wrapper {
    height: $height;
    background-image: none;
  }

  .play-list {
    height: $height;

    li {
      width: $width;

      a {
        position: relative;
      }

      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 150%;
          font-weight: bold;
          position: absolute;
          bottom: 86px;
          text-align: center;
        }

        img {
          position: absolute;
          height: auto;
          width: auto;
          max-width: 90%;
          max-height: 56px;
          bottom: 52px;

          @include desktop {
            max-height: 93px;
            bottom: 72px;
          }

          & + span {
            display: none;
          }
        }

        & + p {
          position: absolute;
          bottom: 18px;
          margin: 0;
          width: 100%;
          text-align: center;

          @include desktop {
            bottom: 36px;
          }
        }
      }

      &:after {
        @include desktop {
          width: $width + 14;
          height: $height + 14;
        }
      }

      &.original {
        position: relative;

        &:before {
          @extend %original;
          opacity: 1;
          position: absolute;
          top: 24px;
          left: calc(50% - 51px);
          z-index: 10;
        }
      }
    }

    img,
    picture {
      height: $height;
      object-fit: cover;
    }
  }

  .category-image {
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 0.4 * $height;
      width: $width;
      position: absolute;
      left: 0;
      z-index: 2;
      opacity: 0.85;
    }

    &:before {
      top: 0;
      background: linear-gradient(0deg, rgba($color-gradient-base, 0) 0%, #010117 100%);
    }

    &:after {
      bottom: 0;
      background: linear-gradient(180deg, rgba($color-gradient-base, 0) 0%, #010117 100%);
    }
  }
}

///////////////

// caregory boxes
.category-section {
  min-height: 288px;
  position: relative;
  clear: both;

  &:empty,
  .placeholder {
    display: none;
  }

  .placeholder {
    list-style: none;
    margin-top: 25px;
    margin-left: -37px;
    width: 3000px;
    overflow: hidden;
    animation: pulsate 1s ease-in-out infinite alternate;

    li {
      display: inline-block;
      flex: 1;
      width: 256px;
      height: 144px;
      background: rgba(72, 72, 72, 0.4);
      border-radius: $border-radius-secondary;
      margin-right: 15px;
    }
  }

  .control {
    outline: none;
    position: absolute;
    z-index: 1;
    top: 50px;
    bottom: 0;
    right: -15px;
    width: 120px;
    text-indent: -9999px;
    background-image: linear-gradient(to right, $color-image-gradient, #010118);

    &.swiper-button-disabled {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 32px;
      right: 15px;
      top: 56px;
      background-image: url(/svg/ic-arrow-right.svg);
      background-size: 16px 32px;
      background-repeat: no-repeat;
      opacity: 0.4;
      transition: 0.25s ease-in-out;
      transition-property: opacity;
    }

    &.prev {
      right: initial;
      left: -15px;
      transform: rotate(180deg);

      &:after {
        top: initial;
        bottom: 56px;
      }
    }

    @include desktop {
      display: block;
    }
  }

  & + .category-section {
    margin-top: 24px;
  }

  a {
    color: $color-body-color;
  }

  h2 {
    height: 56px;
    line-height: 56px;
    font-size: 1.11111111em;
    font-weight: bold;
    margin: 0;
    position: relative;
    z-index: 1;

    a {
      font-size: 0.8em;
      float: right;
      position: relative;
      padding-right: 24px;
      background-image: url(/svg/ic-chevron.svg);
      background-position: 100% 50%;
      background-size: 24px 24px;
      background-repeat: no-repeat;
    }
  }

  .scroll-wrapper {
    overflow: hidden;
    height: 232px;
    padding: 0 15px;
    margin: 0 -15px;

    background-image: linear-gradient(rgba(72, 72, 72, 0), rgba(72, 72, 72, 0.24));
  }

  .play-list {
    position: absolute;
    display: flex;
    flex-direction: row;
    list-style: none;
    height: 232px;
    margin-bottom: 0;
    padding: 0;

    li {
      width: 224px;
      flex-shrink: 0;
      position: relative;
      margin-right: 15px;

      a {
        div {
          margin-bottom: 12px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: '';
        display: block;
        border-radius: $border-radius-big + 3px;
        border: solid 3px rgba(255, 255, 255, 0.24);
        position: absolute;
        left: -7px;
        top: -7px;
        opacity: 0;
        transition: 0.25s ease-in-out;
        transition-property: opacity;
        z-index: 0;
        width: calc(100% + 14px);
        height: 140px;
        cursor: pointer;

        @include desktop {
          width: 270px;
          height: 158px;
        }
      }

      @include hoverSupported {
        &:hover {
          img {
            opacity: 0.72;
          }

          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }
      }

      &:active {
        &:after {
          opacity: 1;
        }
      }

      @include desktop {
        width: 256px;
      }

      & > a:not(.play) {
        display: block;
        position: relative;
        z-index: 1;

        a {
          z-index: 2;
        }

        & > span {
          display: block;
          height: 4px;
          position: absolute;
          z-index: 3;
          top: 106px;
          left: 16px;
          right: 16px;
          background-color: rgba($color-body-color, 0.32);
          border-radius: 4px;

          @include desktop {
            top: 124px;
          }

          span {
            display: block;
            height: 100%;
            background-color: $color-body-color;
            border-radius: inherit;
          }
        }
        .coming-soon {
          position: absolute;
          left: 8px;
          font-weight: bold;
          top: 8px;
          background-color: $color-coming-soon;
          font-size: 0.75rem;
          display: inline;
          padding: 2px 6px;
          border-radius: 1px;
          color: $color-body-color;
          width: auto;
        }
      }

      .play {
        width: 48px;
        height: 48px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        cursor: pointer;
        text-indent: -9999px;
        opacity: 0;
        transition: 0.25s ease-in-out;
        transition-property: opacity;
        top: 34px;

        @include desktop {
          top: 48px;
        }

        &:before,
        &:after {
          transition: 0.25s ease-in-out;
          transition-property: opacity;
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-size: contain;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:before {
          background-image: url(/svg/ic-play-resume.svg);
          opacity: 1;
        }

        &:after {
          background-image: url(/svg/ic-play-resume-red.svg);
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }
      }

      &.resume {
        &:hover {
          .play {
            opacity: 1;
          }
        }
      }
    }

    img,
    picture {
      position: relative;
      border-radius: $border-radius-secondary;
      width: 100%;
      z-index: 1;
      height: auto;
      opacity: 1;
      transition: 0.25s ease-in-out;
      background-color: transparent;
      transition-property: opacity, background-color;
    }

    h3 {
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 2px;
      line-height: 1.25;
    }

    p {
      color: rgba($color-body-color, 0.7);
      line-height: 1.2;
      font-size: 0.8888888889em;
      z-index: 2;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @include desktop {
        width: 220px;
      }
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100px);

    &:before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background-image: url(/svg/ic-loading.svg);
      background-repeat: no-repeat;
      background-size: 32px 32px;
      animation: 1s rotate ease-in-out infinite;
    }
  }

  &-medium {
    @include portraitSection(256px, 351px);

    .play-list {
      li {
        &:after {
          height: 365px;
        }

        .img-placeholder-category-playlist {
          width: 100%;
          height: 351px;
        }

        h3 {
          bottom: 45px;
          left: 24px;
          right: 24px;
          width: initial;
          align-items: flex-start !important;

          span {
            line-height: 30px;
            bottom: 0;
            text-align: left;
          }

          & + p {
            bottom: 24px;
            left: 24px;
            right: 24px;
            text-align: initial;
          }
        }
      }
    }
  }

  &-large {
    @include portraitSection(367px, 504px);

    .scroll-wrapper {
      height: 308px;

      @include desktop {
        height: 504px;
      }
    }

    .category-image {
      &:before,
      &:after {
        width: 224px;
      }

      &:before,
      &:after {
        @include desktop {
          width: 367px;
        }
      }
    }

    .play-list {
      li {
        width: 224px;
        margin-right: 24px;

        a:not(.play) {
          .coming-soon {
            top: auto;
            bottom: 105px;
            left: 50%;
            transform: translateX(-50%);

            @include desktop {
              bottom: 160px;
            }
          }
        }

        .img-placeholder-category-playlist {
          height: 308px;

          @include desktop {
            width: 367px;
            height: 504px;
          }
        }

        &:after {
          height: 322px;

          @include desktop {
            height: 518px;
          }
        }

        img,
        picture {
          height: 308px;
        }

        @include desktop {
          width: 367px;
          height: 504px;

          img,
          picture {
            height: 504px;
          }
        }
      }
    }
  }
}

html.mobile {
  .control {
    display: none;
  }
}
