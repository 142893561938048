.visa {
  background-image: url(/svg/cards/ic-visa.svg) !important;
}

.mastercard {
  background-image: url(/svg/cards/ic-mastercard.svg) !important;
}

.maestro {
  background-image: url(/svg/cards/ic-maestro.svg) !important;
}

.jcb {
  background-image: url(/svg/cards/ic-jcb.svg) !important;
}

.diners {
  background-image: url(/svg/cards/ic-diners.svg) !important;
}
