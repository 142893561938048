.shaka-close-button {
  background-color: transparent;
  border: 0;
  outline: none;
}

.player-error,
.player-loading {
  background: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    max-width: 370px;
    text-align: center;

    h2 {
      font-size: 155%;
      line-height: 34px;
      margin-bottom: 12px;
    }

    p {
      line-height: 22px;
      margin-bottom: 32px;
    }

    * {
      text-align: inherit;
    }
  }
}
