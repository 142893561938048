$padding: 40px;
$padding-desktop: 24px;

footer {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: $padding;

  a {
    &:hover {
      color: $color-body-color;
    }
  }

  a,
  p {
    color: rgba($color-body-color, 0.6);
  }

  p {
    padding: 0 30px;
    text-align: center;
    line-height: 17px;
    margin-bottom: $padding;
    font-size: 0.7777777778em;

    @include desktop {
      margin-bottom: $padding-desktop;

      &:last-child {
        margin-bottom: $padding;
      }
    }
  }

  .socials {
    list-style: none;
    padding: 0 !important;
    border-bottom: 0 !important;
    display: flex;
    justify-content: center;
    margin-top: 32px !important;
    margin-bottom: 32px !important;

    li {
      &:not(:last-child) {
        margin-right: 28px !important;
      }

      a {
        width: 24px;
        height: 24px !important;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
      }
    }

    .instagram {
      a {
        background-image: url(/svg/ic-instagram.svg);
      }
    }

    .facebook {
      a {
        background-image: url(/svg/ic-facebook.svg);
      }
    }

    .youtube {
      a {
        background-image: url(/svg/ic-youtube.svg);
      }
    }

    .tiktok {
      a {
        background-image: url(/svg/ic-tiktok.svg);
      }
    }

    .telegram {
      a {
        background-image: url(/svg/ic-telegram.svg);
      }
    }

    .twitter {
      a {
        background-image: url(/svg/ic-twitter.svg);
      }
    }
  }

  ul:not(.languages) {
    padding-bottom: 54px;
    position: relative;
    text-align: center;
    list-style: none;
    padding: 0;

    border-bottom: 1px solid $color-border;
    margin-bottom: $padding;
    padding-bottom: $padding;

    @include desktop {
      padding-bottom: $padding-desktop;
    }

    a {
      height: 48px;
      line-height: 48px;

      @include desktop {
        height: auto;
        line-height: initial;
      }
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: center;

      li {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .logo {
    margin: 0 auto $padding auto;
    display: block;
    width: 160px;
    height: 55px;
    background-image: url(/svg/ic-logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 160px 55px;
    text-indent: -9999px;

    &:before {
      display: none;
    }
  }
}

.fixed-footer {
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
